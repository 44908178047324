<template>
  <div class="content">
    <div class="events--actions-header pb-3">
      <CButton
        size="sm"
        class="header-icon-buttons mr-3"
        @click.stop.prevent="handleReload"
      >
        <CIcon name="cil-reload"/>
      </CButton>
    </div>
    <CRow class="mt-1">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="eventsTableId"
              class="zq--table"
              :items="tableData"
              :fields="filteredFields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="loading"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:sorter-value="handleSortBy"
              :sorter-value.sync="sorterValue"
            >
              <template #loading>
                <TableLoader />
              </template>
            </CDataTable>
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="totalRecords"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import TableLoader from '@/components/table/Loader.vue';
import TableFooter from '@/components/table/Footer/index.vue';
import { mapActions, mapGetters } from "vuex";
import { pageConfig } from "@/config";

export default {
  name: "EntityLogs",
  components: {
    TableLoader,
    TableFooter,
  },
  data() {
    return {
      ready: false,
      tableData: [],
      itemsPerPage: pageConfig.itemsPerPage,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
      must: null,
      multiFields: [],
      filteredFields: []
    }
  },
  props: {
    entityId: String,
  },
  computed: {
    ...mapGetters('logEvents', ['logEvents', 'loading', 'totalRecords', 'fields', 'pages']),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default';
    },
    sorterValue: {
      get() {
        const sorter = (this.sortBy || [])[0];
        const key = sorter ? sorter.order.toLowerCase() : undefined;
        if (!key) return undefined;

        return {
          column: sorter.queryField,
          desc: key === 'desc',
          asc: key === 'asc',
        };
      },
      set(val) {
        this.sortBy[0] = {
          queryField: val.column,
          order: val.asc ? 'Asc' : 'Desc',
        };
      },
    },
  },
  async created() {
    this.filteredFields = this.fields.filter(field => field !== 'select' && field !== 'actions')

    this.must = [
      {
        queryField: 'entityId',
        queryValues: [this.entityId]
      }
    ];
    await this.queryDb();
    this.ready = true;
  },
  methods: {
    ...mapActions('logEvents', ['handleGetLogEventsByQuery', 'handleFields']),
    async handleReload() {
      await this.queryDb();
    },
    async queryDb() {
      this.tableData = await this.handleGetLogEventsByQuery({
        queryRequest:
          {
            must: this.must,
            multiFields: this.multiFields,
            sortBy: this.sortBy,
            limit: this.itemsPerPage,
            skip: (this.page - 1) * this.itemsPerPage
          }
      });
    },
    async itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      await this.queryDb();
    },
    async paginationChange(val) {
      this.page = val;
      await this.queryDb();
      await this.updateFields();
    },
    async updateFields(val) {
      await this.handleFields(val);
    },
    async handleSortBy(e) {
      this.sortBy = [
        {
          queryField: e.column,
          order: e.asc ? 'Asc' : 'Desc',
        },
      ];
      this.page = 1;
      await this.queryDb();
    },
  },
}
</script>

<style scoped lang="scss">
.events {
  &--actions-header {
    display: flex;
    justify-content: flex-end;
  }
}
.zq--wizard-card {
  &__top {
    z-index: 99;
  }
  .header-collapse-row {
    position: relative;

    .reload-btn {
      display: flex;
      align-items: center;
      position: absolute;
      top: 10%;
      right: 50px;

      & > svg {
        margin-left: 5px;
      }
    }
  }
}
</style>