<template>
  <CRow class="dependantOn-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="isCollapsedHandler">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.dependantOn.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--      Should Match At Least      -->
            <ClCardRow
              :label="texts.createPage.summary.dependantOn.shouldLabel"
              :content="tableData.formData.shouldMatchAtLeast"
            />
            <!-- TABLE -->
            <CDataTable
              v-if="tableData.selectedData.length"
              id="achTableResult"
              class="zq--table zq--table--default-pagination"
              :items="tableData.selectedData"
              :fields="fields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              pagination
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
            >
              <!--      CREATED         -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <!--       Description           -->
              <template #description="{ item }">
                <td>
                  <span v-html="item.description"></span>
                </td>
              </template>
              <!--       Dependency           -->
              <template #dependency="{ item }">
                <td>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="action-buttons-list">
                      <button
                        class="action-buttons action-buttons__must-not"
                        :class="{'action-buttons--active__must-not': tableData.formData.mustNot.includes(item.id)}"
                      >
                        {{ texts.summary.entrants.mustNotText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__should"
                        :class="{'action-buttons--active__should': tableData.formData.should.includes(item.id)}"
                      >
                        {{ texts.summary.entrants.shouldText }}
                      </button>
                      <button
                        class="action-buttons action-buttons__must"
                        :class="{'action-buttons--active__must': tableData.formData.must.includes(item.id)}"
                      >
                        {{ texts.summary.entrants.mustText }}
                      </button>
                    </div>
                  </div>
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {mapActions, mapGetters} from "vuex";
import {pageConfig} from "@/config";
import {competitionsTexts} from "@/config/pageTexts/competitions.json";
import {dateFormate} from "@/utils";

export default {
  name: 'ViewDependantOn',
  components: {
    ClCardRow,
    ClCardArrow
  },
  props: {
    dependantOnData: Object
  },
  data() {
    return {
      texts: {
        ...competitionsTexts
      },
      basicIsCollapsed: false,
      data: {},
      dataMap: {},
      itemsPerPage: pageConfig.itemsPerPage,
      fields: [
        "id",
        "name",
        "created",
        "description",
        "dependency"
      ],
      tableData: {
        selectedData: [],
        formData: {},
      },
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('achievements', ['handleGetAchievements']),
    initialize() {
      this.tableData.formData = this.dependantOnData;
    },
    isCollapsedHandler() {
      this.basicIsCollapsed = !this.basicIsCollapsed;

      if (this.basicIsCollapsed  && !this.tableData.selectedData.length) this.getSelectedData();
    },
    async getSelectedData() {
      const selectedIds = [
        ...this.dependantOnData.must,
        ...this.dependantOnData.mustNot,
        ...this.dependantOnData.should
      ];

      if (selectedIds.length) {
        this.tableData.selectedData = await this.handleGetAchievements({idArray: selectedIds});
      }
    },
    dateFormate(val) {
      return dateFormate(val);
    },
  },
}
</script>

<style lang="scss">
.dependantOn-detail {

}
</style>