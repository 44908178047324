<template>
  <div class="preview-webhook">
    <!--   Header   -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ formData.name }} </h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" :active-tab="activeTab" @update:activeTab="setActiveTab">
      <CTab :title="texts.previewPage.webhookTab">
        <WebhookDetails></WebhookDetails>
      </CTab>
      <CTab :title="texts.previewPage.systemLogsTab" v-if="entityId">
        <EntityLogs :entityId="entityId" v-if="activeTab === 1" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import WebhookDetails from '@/generated/ziqni/views/webhooks/WebhookDetails';
import EntityLogs from '@/shared/components/supportModels/logEvents/EntityLogs.vue';
import { mapGetters } from "vuex";
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/display/autorefresh.js';
import IconWithTooltip from '@/shared/UI/IconWithTooltip';
import { webhooks } from "@/config/descriptions/webhooks.json";
import { webhooksTexts } from "@/config/pageTexts/webhooks.json";

export default {
  name: 'PreviewWebhook',
  components: {
    EntityLogs,
    WebhookDetails,
    IconWithTooltip
  },
  data() {
    return {
      texts: {
        ...webhooksTexts
      },
      descriptions: {
        ...webhooks.preview
      },
      formData: {
        name: '',
        source: '',
      },
      options: {
        lineNumbers: true,
        readOnly: true,
        autoRefresh: true,
      },
      activeTab: 0,
      entityId: null,
    };
  },
  computed: {
    ...mapGetters('webhooks', ['webhook']),
  },
  mounted() {
    this.entityId = this.$route.params.id;
  },
  watch: {
    webhook: {
      deep: true,
      handler: function (val) {
        this.formData = val;
      },
    },
  },
  methods: {
    setActiveTab(val) {
      this.activeTab = val;
    },
  }
}
</script>

<style lang="scss">
.preview-webhook {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.webhook-detail-page {
  height: 100%;
  flex: 2;
  background: var(--zq-sub-bg);
}
</style>