export const defaultRoutines = [
    {
        routine: 'increment',
        parameter: {
            scope: '$variable',
            type: 'number',
            macros: [
                {
                    scope: '$variable',
                    value: '$scorecard.points'
                }
            ]
        },
        arguments: [
            {
                order: 1,
                scope: "parameter",
                argument: 'by',
                type: 'number',
                value: 1
            }
        ],
    },
    {
        routine: 'decrement',
        parameter: {
            scope: '$variable',
            type: 'number',
            macros: [
                {
                    scope: '$variable',
                    value: '$scorecard.points'
                }
            ]
        },
        arguments: [
            {
                order: 1,
                scope: "parameter",
                argument: 'by',
                type: 'number',
                value: 1
            }
        ],
    },
    {
        routine: 'set',
        parameter: {
            scope: '$variable',
            type: 'number'
        },
        arguments: [
            {
                order: 1,
                scope: "parameter",
                argument: 'to',
                type: 'number',
                value: 1
            }
        ],
    },
    {
        routine: 'add',
        parameter: {
            scope: '$variable',
            type: 'number',
            macros: [
                {
                    scope: '$variable',
                    value: '$event.points'
                },
                {
                    scope: '$variable',
                    value: '$event.source.value'
                }
            ]
        },
        arguments: [
            {
                order: 1,
                scope: 'parameter',
                argument: 'to',
                type: '$variable',
                macros: [
                    {
                        scope: '$variable',
                        value: '$scorecard.points'
                    }
                ]
            }
        ],
    }
];