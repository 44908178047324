<template>
  <CRow class="pb-3 preview-actions">
    <CCol col="12" class="d-flex justify-content-end">
      <CButton
        v-if="isReloadEnabled"
        size="sm"
        class="header-icon-buttons mb-2 ml-3 mr-3"
        @click="handleReload"
      >
        <CIcon name="cil-reload"/>
      </CButton>
      <CDropdown
          inNav
          class="c-header-nav-items custom-dropbox zq--actions-dropdown"
          placement="bottom-end"
          add-menu-classes="pt-0"
      >
        <template #toggler class="justify-content-end">
          <CButton
              :disabled="loading"
              class="zq--custom-button"
              :color="colorBtn"
              variant="outline">
            {{$t('buttons.actions')}}
            <i
                v-if="theme === 'main'"
                class="fa fa-angle-down font-weight-bold"></i>
            <i v-else class="fa fa-caret-down"></i>
          </CButton>
        </template>
        <CDropdownItem class="p-0" v-if="isEditEnabled">
          <CButton
              pill
              :disabled="loading"
              :color="colorBtn"
              class="action-custom-button"
              variant="ghost"
              @click="handleEdit"
          >
            {{$t('buttons.edit')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isDeleteEnabled">
          <CButton
              :disabled="loading"
              pill
              :color="colorBtn"
              class="action-custom-button"
              variant="ghost"
              @click="handleDelete"
          >
            {{ $t(deleteActionTitle) }}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isExportEntrantsEnabled">
          <CButton
            :disabled="loading"
            pill
            :color="colorBtn"
            class="action-custom-button"
            variant="ghost"
            @click="handleExportEntrants"
          >
            {{$t('buttons.exportEntrants')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isDownloadEnabled">
          <CButton
              pill
              :disabled="loading"
              :color="colorBtn"
              class="action-custom-button"
              variant="ghost"
              @click="handleDownload"
          >
            {{$t('buttons.download')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isStartEnabled">
          <CButton
            pill
            :disabled="isStroked"
            :color="colorBtn"
            :class="{ 'action-custom-button--stroked': isStroked }"
            class="action-custom-button"
            variant="ghost"
            @click="handleStart"
          >
            {{$t('buttons.start')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isStopEnabled">
          <CButton
            pill
            :disabled="loading"
            :color="colorBtn"
            class="action-custom-button"
            variant="ghost"
            @click="handleStop"
          >
            {{$t('buttons.stop')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isStartCompetitionAction">
          <CButton
              pill
              :disabled="isStroked"
              :color="colorBtn"
              :class="{ 'action-custom-button--stroked': isStroked }"
              class="action-custom-button"
              variant="ghost"
              @click="handleStartCompetition"
          >
            {{$t('buttons.start')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isFinishCompetitionAction">
          <CButton
              pill
              :disabled="isStroked"
              :color="colorBtn"
              :class="{ 'action-custom-button--stroked': isStroked }"
              class="action-custom-button"
              variant="ghost"
              @click="handleFinishCompetition"
          >
            {{$t('buttons.finish')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isFinaliseCompetitionAction">
          <CButton
            pill
            :disabled="isStroked"
            :color="colorBtn"
            :class="{ 'action-custom-button--stroked': isStroked }"
            class="action-custom-button"
            variant="ghost"
            @click="handleFinaliseCompetition"
          >
            {{$t('buttons.finalise')}}
          </CButton>
        </CDropdownItem>
        <CDropdownItem class="p-0" v-if="isCancelCompetitionAction">
          <CButton
              pill
              :disabled="isStroked"
              :color="colorBtn"
              :class="{ 'action-custom-button--stroked': isStroked }"
              class="action-custom-button"
              variant="ghost"
              @click="handleCancelCompetition"
          >
            {{$t('buttons.cancel')}}
          </CButton>
        </CDropdownItem>
      </CDropdown>
    </CCol>
  </CRow>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    deleteTitle: {
      type: String,
      default: 'delete'
    },
    isDeleteEnabled: {
      type: Boolean,
      default: true
    },
    isExportEntrantsEnabled: {
      type: Boolean,
      default: false
    },
    isEditEnabled: {
      type: Boolean,
      default: true
    },
    isDownloadEnabled: {
      type: Boolean,
      default: false
    },
    isReloadEnabled: {
      type: Boolean,
      default: false
    },
    isStartEnabled: {
      type: Boolean,
      default: false
    },
    isStopEnabled: {
      type: Boolean,
      default: false
    },
    isStroked: {
      type: Boolean,
      default: false
    },
    isStartCompetitionAction: {
      type: Boolean,
      default: false
    },
    isFinishCompetitionAction: {
      type: Boolean,
      default: false
    },
    isFinaliseCompetitionAction: {
      type: Boolean,
      default: false
    },
    isCancelCompetitionAction: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapGetters('theme', ['theme']),
    deleteActionTitle(){
      if(this.deleteTitle === 'archive') {
        return 'buttons.Archived'
      }
      return  'buttons.delete'
    },
    colorBtn() {
      switch (this.theme) {
        case 'main': {
          return ''
        }
        case 'default': {
          return 'dark'
        }
        default: {
          return ''
        }
      }
    }
  },
  methods: {
    handleEdit() {
      this.$emit('edit');
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleExportEntrants() {
      this.$emit('exportEntrants');
    },
    handleDownload() {
      this.$emit('download');
    },
    handleReload() {
      this.$emit('reload');
    },
    handleStart() {
      if (!this.isStroked) {
        this.$emit('start');
      }
    },
    handleStop() {
      this.$emit('stop');
    },
    handleStartCompetition() {
      this.$emit('startCompetition')
    },
    handleFinishCompetition() {
      this.$emit('finishCompetition')
    },
    handleFinaliseCompetition() {
      this.$emit('finaliseCompetition')
    },
    handleCancelCompetition() {
      this.$emit('cancelCompetition')
    }
  }
}
</script>

<style lang="scss">
.preview-actions {
  .action-custom-button--stroked {
    text-decoration: line-through;
  }

  .custom-dropbox {
    list-style: none;

    .dropdown-menu {
      min-width: 90px;
    }
  }

  .action-custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

</style>