<template>
  <div class="zq--table-loader-wrapper">
    <img
      class="zq--table-loader-icon"
      src="@/assets/icons/logo.svg"
      alt=""
    >
  </div>
</template>

<script>
  export default {
    name: "TableLoader"
  }
</script>

<style lang="scss">
  .zq--table-loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
  }

  .zq--table-loader-icon {
    animation: zq-table-loader 2.5s linear infinite normal;
    position: fixed;
    top: 285px;
    z-index: 999999;
    left: calc(50% - 24px);
    opacity: 0.4;
    width: 47px;
    height: 47px;
  }

  @keyframes zq-table-loader {
    0% {
      height: 47px;
      width: 47px;
    }
    100% {
      transform: rotate(360deg);
    }
  }
</style>