<template>
  <CRow class="schedule-detail">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.scheduling.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody>
            <!--       Schedule Type        -->
            <ClCardRow :label="texts.createPage.summary.scheduling.typeLabel" :content="data.scheduleType"/>
            <!--       EVERY       -->
            <ClCardRow :label="texts.createPage.summary.scheduling.everyLabel" v-if="isEvery">
              <template #content>
                <ul class="zq--list">
                  <li v-for="(group, index) in every"
                      :key="index" class="group px-2 py-1 mr-2">{{ group }}
                  </li>
                </ul>
              </template>
            </ClCardRow>
            <!--       Schedule Occurrences Limit        -->
            <ClCardRow :label="texts.createPage.summary.scheduling.occurrencesLabel" :content="data.scheduleOccurrencesLimit" />
            <!--       Start Date        -->
            <ClCardRow :label="texts.createPage.summary.scheduling.startLabel" :content="dateFormate(data.startDate)" />
            <!--       End Date        -->
            <ClCardRow :label="texts.createPage.summary.scheduling.endLabel" :content="dateFormate(data.endDate)" />
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {dateFormate} from '@/utils/dateFormate';
import {achievementsTexts} from "@/config/pageTexts/achievements.json";
import {cloneDeep} from "lodash";

export default {
  name: 'ViewScheduling',
  components: {
    ClCardRow,
    ClCardArrow
  },
  props: {
    schedulingData: Object
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false,
      data: {}
    }
  },
  created() {
    this.data = cloneDeep(this.schedulingData);
  },
  computed: {
    every() {
      if (this.data.scheduleType === 'Daily') {
        return this.data.every.reduce((acc, item) => {
          return [
            ...acc,
            ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][item - 1]
          ]
        }, [])
      }

      if (this.data.scheduleType === 'Monthly') {
        return this.data.every.reduce((acc, item) => {
          return [
            ...acc,
            ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][item - 1]
          ]
        }, [])
      }

      if (this.data.scheduleType === 'Weekly') {
        return this.data.every.reduce((acc, item) => {
          return [...acc, `week ${item}`]
        }, [])
      }

      return null;
    },
    isEvery() {
      return !!(this.data.every && this.data.every.length);
    }
  },
  methods: {
    dateFormate(val) {
      return val ? dateFormate(val) : ''
    },
  },
  watch: {
    schedulingData: {
      deep: true,
      handler(val) {
        this.data = val;
      }
    }
  }
}
</script>

<style lang="scss">
.schedule-detail {
  .every-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;

    li {
      background: #f1f1f1 none repeat scroll 0 0;
      border: 1px solid #f1f1f1;
      cursor: pointer;
      float: left;
      font-size: 16px;
      line-height: 30px;
      margin: 0 2px 2px 0;
      min-width: 45px;
      padding: 0 3px;
      text-align: center;
    }
  }

  @media screen and (max-width: 576px) {
    .overview-content {
      text-align: center;
    }
  }
}
</style>